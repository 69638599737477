<template>
  <!-- <div class="home  home_pc"> -->
  <div class="domain_content">

    <section id="section-1" class="section-content no-padding">
      <div class="head-row">
        <div class="app-intro">
          <h1 class="section-1-rule" v-html="$global.formatstr(doc.s1.h1, month)"></h1>

          <p>
            We're thrilled to introduce the latest version of OB WhatsApp Omar Al-Anabi for 2024, designed with enhanced features to enrich your messaging experience. In this page, we'll take a closer look at why OB WhatsApp has garnered so much attention and provide you with a direct download link to seamlessly upgrade to the newest version. Whether you're keeping in touch with friends or family, the updated OB WhatsApp offers a range of unique features to enhance your communication. Best of all, you can download OB WhatsApp for free on your Android device and enjoy its powerful new tools!
          </p>

          <myimg name="ob-new" alt="obwhatsapp apk" />
          
        </div>
      </div>

      <DownloadBtn class="top-download fix has-exp" :exp-data="`show_${pageName}home_download`"
        :name="pageName" :from="from" :filename="filename" :isOffical="isOffical" txt="Download OBWhatsApp"/>

      <h2>
        Why OB WhatsApp Stands Out
      </h2>
      <p>
        OB WhatsApp has become one of the most popular modified alternatives to the original WhatsApp, offering a variety of customization and enhanced privacy features. This makes it an appealing option for users who want more control over their messaging app. Here's why OB WhatsApp is winning over users:
      </p>
      <ul class="common-list number-list">
        <li>
          <strong>Personalization Options:</strong> From custom themes to adjustable fonts and colors, OB WhatsApp lets you personalize your chat experience, so you can express yourself with more flair.
        </li>
        <li>
          <strong>Enhanced Privacy:</strong> The app offers greater privacy control, allowing users to hide their last seen, status, and profile photo from specific contacts or groups, ensuring a more private messaging experience.
        </li>
        <li>
          <strong>Advanced Features:</strong> OB WhatsApp allows you to send larger files, schedule messages, and even set up automatic replies—offering features well beyond the standard app.
        </li>
        <li>
          <strong>Anti-Ban Protection:</strong> The developers have incorporated anti-ban mechanisms, reducing the risk of your account being flagged or banned while using the modified app.
        </li>
      </ul>

    </section>

    <S2 :doc="doc" />

    <S3 :doc="doc" :pageName="pageName" />

    <div id="snippet-box">
      <div class="snippet-title">Summary</div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">Author Rating</div>
          <div class="snippet-data-img"><span class="star-img"><img src="@/assets/1star.png"
                alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span></div>
          <div class="snippet-clear"></div>
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">Aggregate Rating</div>
            <div class="snippet-data-img"><span itemprop="ratingValue">3.65</span> based on <span
                class="rating-count" itemprop="ratingCount">46258</span> votes </div>
          </div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Software Name</div>
          <div class="snippet-data-img"> <span itemprop="name">OBWhatsApp APK</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Operating System</div>
          <div class="snippet-data-img"> <span itemprop="operatingSystem">Android</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Software Category</div>
          <div class="snippet-data-img"> <span itemprop="applicationCategory">App</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Price</div>
          <div class="snippet-data-img"> <span itemprop="offers" itemscope=""
              itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Landing Page</div>
          <div class="snippet-data-img"> <a itemprop="featureList"
            href="https://gbapksdownload.com/ ">https://gbapksdownload.com/</a></div>
            <div class="snippet-clear"></div>
        </div>
      </div>
    </div>

    <Rate />

  </div>
</template>

<script>
// @ is an alias to /src
import "@/css/gb/pc.scss";
import DownloadBtn from "@/components/DownloadBtn.vue";
import S2 from "./assets/section2.vue";
import S3 from "./assets/section3.vue";
import ApkDetail from "./assets/apkdetail.vue";
import Rate from "@/components/rate.vue";

export default {
  name: "ob",
  components: {
    DownloadBtn,
    S2,
    S3,
    ApkDetail,
    Rate,
  },
  data () {
    return {
      pageName: "ob",
      doc: null,
      myApp: null,
      from: "ob",
      filename: "ob",
      feature: [
        {
          feature: "القرآن",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "إخفاء حالة الاتصال بالإنترنت",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "وضع الطيران",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "تنزيل الحالة",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "أضف خطوطًا / ملصقات مخصصة",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "تقاسم وسائل الاعلام",
          gb: "Maximum 200MB",
          whs: "Maximum 15MB",
        },
        {
          feature: "طول حرف الحالة",
          gb: "Maximum 255",
          whs: "Maximum 139",
        },
        {
          feature: "الموضوعات المدعومة",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "وضع DND",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "تجميد آخر ظهور",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "تعطيل العلامة المعاد توجيهها",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "تعطيل / تخصيص الاتصال",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "حالة مكافحة الحذف / الرسائل",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "قفل الأمان",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "التخصيص بالكامل",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "رد آلي",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "زيادة الحد الامامي",
          gb: "Maximum 250",
          whs: "Maximum 10",
        },
        {
          feature: "زيادة حد مشاركة الصور",
          gb: "Maximum 100",
          whs: "Maximum 30",
        },
      ],
      moreItem: [
        {
          name: "vml",
          icon: require("@/assets/gb/vml.png"),
          advertise: "Status Downloader",
          apk: "https://cdn.gbwhatsapp.download/app/android/apk/Status-Downloader.apk",
        },
        {
          name: "play",
          icon: require("@/assets/gb/play.png"),
          advertise: "WhatsApp Player",
          apk: "https://cdn.gbwhatsapp.download/app/android/apk/WhatsApp-Player.apk",
        },
        {
          name: "clean",
          icon: require("@/assets/gb/clean.png"),
          advertise: "WhatsApp Cleaner",
          apk: "https://cdn.gbwhatsapp.download/app/android/apk/WhatsApp-Cleaner-2.2.04.apk",
        },
        {
          name: "wall",
          icon: require("@/assets/gb/wall.png"),
          advertise: "WhatsApp Wallpaper",
          apk: "https://cdn.gbwhatsapp.download/app/android/apk/WhatsApp-Wallpaper.apk",
        },
      ],

      viewHeight: null,
      scrollEventFun: null,

      month: "",

      ratingStar: null,
      showCollect: false,
      isOffical: 0,
    };
  },
  created () {
    this.myApp = this.$store.state.ob;
    this.doc = this.myApp.doc;
  },
  beforeMount () {
    [this.from, this.filename, this.isOffical] = [
      this.$global.GetQueryString("from") || "",
      this.$global.GetQueryString("filename") || "",
      parseInt(this.$global.GetQueryString("isOffical")) || 0,
    ];
    console.log(`from:${this.from},filename:${this.filename}`);

    this.$store.state.activeName = this.myApp.name;
    this.$store.state.activeLogo = this.myApp.logo;

    const monthArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.month = monthArray[new Date().getMonth()];

    this.$logEvent(`show_obhome_${this.$route.name}`);
  },
  mounted () {
    console.log("mounted");
    let script = document.querySelector("#script");
    if (script) {
      return;
    }

    let recaptchaScript = document.createElement("script");
    recaptchaScript.async = true;
    recaptchaScript.id = "script";
    recaptchaScript.setAttribute(
      "src",
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3240446016388496"
    );
    recaptchaScript.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(recaptchaScript);
    setTimeout(() => {
      if (typeof window !== "undefined") {
        this.viewHeight = window.innerHeight;
        this.scrollEvent();
        // 监听scroll事件
        this.scrollEventFun = this.$global.throttle(
          this.scrollEvent,
          300
        );
        window.addEventListener("scroll", this.scrollEventFun, false);
      }

      if (this.$route.params.scrollTo) {
        document
          .querySelector(`#${this.$route.params.scrollTo}`)
          .scrollIntoView({
            behavior: "smooth",
          });
      }
    }, 1000);
  },
  beforeDestroy () {
    // 防止内存溢出
    window.removeEventListener("scroll", this.scrollEventFun, false);
  },
  methods: {
    getOffsetTop (element) {
      let actualTop = element.offsetTop;
      let current = element.offsetParent;
      while (current !== null) {
        actualTop += current.offsetTop;
        current = current.offsetParent;
      }
      return actualTop;
    },
    // 获取窗口滚动高度
    scrollTop () {
      return (
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset
      );
    },
    scrollEvent () {
      let $scrollTop = this.scrollTop();
      let nodeArr = document.querySelectorAll(".has-exp");
      if ($scrollTop >= 20) {
        this.showTeleMsg = false;
      }
      for (let i = 0; i < nodeArr.length; i++) {
        let domTop =
          this.getOffsetTop(nodeArr[i]) +
          nodeArr[i].getBoundingClientRect().height / 3;
        // 当正文部分露出即发此曝光
        if (nodeArr[i].getAttribute("detail-article") === "1") {
          domTop = this.getOffsetTop(nodeArr[i]);
        }
        let topHeight = this.getOffsetTop(nodeArr[i]) - $scrollTop;
        // console.log('topHeight', topHeight);
        let bottomHeight = domTop - $scrollTop;
        if (topHeight >= 0 && bottomHeight <= this.viewHeight) {
          if (!nodeArr[i].getAttribute("data-exposure")) {
            nodeArr[i].setAttribute("data-exposure", "1");
            const _this = this;
            new Promise((resolve) => {
              resolve();
            }).then(() => {
              let expData = nodeArr[i].getAttribute("exp-data");
              _this.expPost(expData);
            });
          }
        }
        if (topHeight > this.viewHeight) return false;
      }
    },
    expPost (exposeData) {
      this.$logEvent(exposeData);
      console.log(`曝光统计log ${exposeData}`);
    },
    download () {
      this.$logEvent("click_gwhome_download");
    },
    downloadad (name, apk) {
      this.$global.download(apk);
      this.$logEvent(`click_obhome_${name}_download`);
    },

    gotochat () {
      if (typeof window !== "undefined") {
        // 在浏览器环境中执行的代码
        window.location.href = "https://www.whatsplus.online/";
      }
    },
  },
};
</script>
