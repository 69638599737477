<template>
	<section id="section-2" class="section-content">
		<h2 v-html="doc.s2.title_2"></h2>

		<p>
			The 2024 version of OB WhatsApp Omar Al-Anabi introduces several exciting improvements designed to boost both performance and usability. Here's a breakdown of the latest features:
		</p>

		<!-- <div class="advertisment-item">
			<lazypic :img="doc.s2.adimg_1"></lazypic>
		</div> -->

		<div class="intro-li-row">
			<ul class="list-icons">
				<li v-for="(data, i) in doc.s2.ul" :key="i">
					<i class="fa"></i>
					<strong v-html="data.head"></strong>
					<div class="list-detail" v-html="data.content"></div>
					<div class="list-img" v-if="data.img">
						<lazypic :img="data.img"></lazypic>
					</div>
				</li>
			</ul>
		</div>

		<h2>
			Installation Guide: How to Download OB WhatsApp on Android
		</h2>
		<p>
			Since OB WhatsApp isn't available on official app stores like Google Play, you'll need to download and install it manually using an APK file. Here's a simple step-by-step guide to get started:
		</p>
		<ul class="common-list number-list">
			<li><strong>Enable "Unknown Sources": </strong>Before installing the APK, go to your phone's settings, navigate to "Security," and enable the option for "Unknown Sources." This allows you to install apps from third-party sources.</li>
			<li><strong>Download the APK: </strong>Visit a trusted website to download the OB WhatsApp APK file. Ensure that you're using a reliable source to avoid security risks.</li>
			<li><strong>Install the APK: </strong>Once downloaded, open the APK file and follow the on-screen instructions to install OB WhatsApp on your device.</li>
		</ul>
		<p>By following these steps, you'll be able to set up OB WhatsApp and enjoy its enhanced features without hassle!</p>

	</section>
</template>

<script>
import lazypic from "@/components/lazypic.vue";

export default {
	name: 's2',
	components: {
		lazypic,
	},
	props: {
		doc: Object,
	},
	methods: {
		gotochat() {
			window.location.href = 'https://www.whatsplus.site/'
		},
		gotoorg() {
			window.location.href = 'https://www.whatspro.org/'
		},
	},
}
</script>
